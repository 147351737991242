import React from 'react'
import styled from 'styled-components'
import { Section } from 'src/components/Layout'
import { Heading } from 'src/components/Typography'
import { motion } from 'framer-motion'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* grid-auto-flow: column; */

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Left = styled(motion.div)`
  background-color: var(--squash300);
  padding: 32px 40px 32px 40px;

  @media (min-width: 800px) {
    padding: 40px 50px 40px 40px;
  }
`

const Right = styled(motion.div)`
  background-color: var(--turquoise300);
  padding: 32px 40px 32px 40px;

  @media (min-width: 800px) {
    padding: 40px 40px 40px 50px;
  }
`

const leftVariant = {
  hidden: { x: '-100%' },
  visible: {
    x: 0,
    transition: { duration: 0.5, delayChildren: 0.5, staggerChildren: 0.1 },
  },
}

const rightVariant = {
  hidden: { x: '100%' },
  visible: {
    x: 0,
    transition: { duration: 0.5, delayChildren: 0.5, staggerChildren: 0.1 },
  },
}

export default function Interactve({ top, zIndex }) {
  return (
    <Section
      $zIndex={zIndex}
      $bgColour={{ desktop: 'var(--purple900)', mobile: 'var(--purple900)' }}
    >
      <Grid>
        <Left initial="hidden" animate="visible" variants={leftVariant}>
          <Heading
            $preset="h3"
            as="h2"
            $colour={{ desktop: 'var(--white)', mobile: 'var(--white)' }}
            $align={{ desktop: 'right', mobile: 'center' }}
          >
            inter
          </Heading>
          <Heading
            $preset="h5"
            as="p"
            $colour={{
              desktop: 'var(--purple900)',
              mobile: 'var(--purple900)',
            }}
            $align={{ desktop: 'right', mobile: 'center' }}
          >
            Open communications
          </Heading>
        </Left>
        <Right initial="hidden" animate="visible" variants={rightVariant}>
          <Heading
            $preset="h3"
            as="h2"
            $colour={{ desktop: 'var(--white)', mobile: 'var(--white)' }}
            $align={{ desktop: 'left', mobile: 'center' }}
          >
            active
          </Heading>
          <Heading
            $preset="h5"
            as="p"
            $colour={{
              desktop: 'var(--purple900)',
              mobile: 'var(--purple900)',
            }}
            $align={{ desktop: 'left', mobile: 'center' }}
          >
            Engaging environments
          </Heading>
        </Right>
      </Grid>
    </Section>
  )
}
