import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { getElements } from 'src/lib/layout/getElements'

const ColumnList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column: 1 / -1;
  grid-auto-flow: row;
  gap: 40px;

  @media (min-width: 800px) {
    grid-column: ${({ $bleed, $width }) => {
      const condensed = $width === 'condensed'
      const hasLeftBleed = $bleed?.includes('left')
      const hasRightBleed = $bleed?.includes('right')
      const leftBleedOnly = hasLeftBleed && !hasRightBleed
      const rightBleedOnly = !hasLeftBleed && hasRightBleed
      const hasBothBleed = hasLeftBleed && hasRightBleed

      switch (true) {
        case leftBleedOnly && condensed:
          return '2 / -1'
        case rightBleedOnly && condensed:
          return '1 / 12'
        case hasBothBleed && condensed:
          return '2 / 12'
        default:
          return '1 / -1'
      }
    }};
    grid-auto-flow: column;
  }

  ${({ $count }) => {
    switch ($count) {
      case 1:
        return oneColumn
      case 2:
        return twoColumn
      case 3:
        return threeColumn
      default:
        throw new Error(
          `[Error] Number of columns out of range (1-3): ${$count}`
        )
    }
  }}
`

const Column = styled.div``

const oneColumn = css`
  ${Column} {
    grid-column: 1 / -1;
  }
`

const twoColumn = css`
  ${Column} {
    grid-column: 1 / -1;
  }

  ${({ $collapseOrder }) =>
    $collapseOrder === 'reverse' &&
    `
      ${Column}:nth-child(2n + 1) {
        grid-row: 2;
      }
      ${Column}:nth-child(2n + 2) {
        grid-row: 1;
      }
    `}

  @media (min-width: 800px) {
    ${Column}:nth-child(2n + 1) {
      grid-column: 1 / 4;
      grid-row: 1;
    }
    ${Column}:nth-child(2n + 2) {
      grid-column: 4 / 7;
      grid-row: 1;
    }
  }
`

const threeColumn = css`
  ${Column} {
    grid-column: 1 / -1;
  }

  @media (min-width: 800px) {
    ${Column}:nth-child(3n + 1) {
      grid-column: 1 / 3;
    }

    ${Column}:nth-child(3n + 2) {
      grid-column: 3 / 5;
    }
    ${Column}:nth-child(3n + 3) {
      grid-column: 5 / 7;
    }
  }
`

interface ColumnsSectionProps {
  data?: GatsbyTypes.ColumnsFieldsFragment
  top: boolean
  zIndex: number
}

export default function Columns({ data, top, zIndex }: ColumnsSectionProps) {
  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      <ColumnGrid $bleed={data?.layout?.bleed}>
        <ColumnList
          $bleed={data?.layout?.bleed}
          $width={data?.layout?.width}
          $count={data?.columns?.length}
          $collapseOrder={data?.layout?.collapseOrder}
        >
          {data?.columns?.map((column, index) => (
            <Column key={index}>{getElements(column.elements)}</Column>
          ))}
        </ColumnList>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment ColumnsFields on WpContent_Acf_Layouts_Columns {
    acfeFlexibleLayoutTitle
    anchor
    background {
      kind
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    layout {
      width
      bleed
      collapseOrder
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    columns {
      elements {
        ...ElementFields
      }
    }
  }
`
