import React from 'react'
import styled from 'styled-components'
import { fontRoman } from 'src/lib/typography'
import { FieldError, useController } from 'react-hook-form'

const TextInputGroup = styled.div`
  cursor: text;
  flex-basis: 100%;
  min-height: 66px;
  width: 100%;

  position: relative;

  @media (min-width: 800px) {
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`

const Field = styled.div`
  box-sizing: border-box;
  margin: 8px 0 0;
  position: relative;
  width: 100%;
`

interface ControlledInputProps {
  $hasValue?: boolean
  $hasError?: boolean
  type?: string
  id?: string
  name?: string
}

interface ControlledLabelProps {
  $hasValue?: boolean
  $hasError?: boolean
}

const Input = styled.input<ControlledInputProps>`
  background-color: ${({ $hasValue }) =>
    $hasValue ? 'var(--white)' : 'var(--squash50)'};
  border: ${({ $hasValue, $hasError }) =>
    $hasError
      ? '1px solid red'
      : $hasValue
      ? '1px solid var(--purple900)'
      : 'none'};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ $hasError }) => ($hasError ? 'red' : 'var(--black)')};
  padding: 10px 16px;
  ${fontRoman}
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  transition: background-color 0.5s, border 0.5s;

  &:active,
  &:focus {
    background-color: var(--white);
    border: 1px solid var(--purple900);
  }

  @media (min-width: 800px) {
    font-size: 18px;
    line-height: 28px;
  }
`
const Label = styled.span<ControlledLabelProps>`
  /* background-color: ${({ $hasValue }) =>
    $hasValue ? 'var(--white)' : 'transparent'}; */
  background-color: transparent;
  color: ${({ $hasValue, $hasError }) =>
    $hasError ? 'red' : $hasValue ? 'var(--purple900)' : 'var(--black)'};
  ${fontRoman}
  font-size: 16px;
  line-height: 24px;
  max-width: 90%;
  padding: ${({ $hasValue }) => ($hasValue ? '0 4px' : '0')};
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ $hasValue }) =>
    $hasValue
      ? 'translate(14px, 1px) scale(0.6)'
      : 'translate(16px, 10px) scale(1)'};
  transform-origin: top left;
  transition: background-color 0.1s, transform 0.5s;

  ${Input}:active + &,
  ${Input}:focus + & {
    background-color: var(--white);
    color: var(--purple900);
    padding: 0 4px;
    transform: translate(14px, 1px) scale(0.6);
  }

  @media (min-width: 800px) {
    font-size: 18px;
    line-height: 28px;
  }
`
const Error = styled.span`
  color: red;
  display: block;
  ${fontRoman};
  font-size: 12px;
  line-height: 18px;
  margin: 8px 0 8px 16px;
`

interface TextInputProps {
  type?: string
  id?: string
  name: string
  label: string
  error?: FieldError
}

interface UncontrolledTextInputProps extends TextInputProps {
  register: any
}

export function TextInput({
  register,
  type,
  id,
  name,
  label,
  error,
  ...rest
}: UncontrolledTextInputProps) {
  return (
    <TextInputGroup>
      <Field>
        <label htmlFor={id}>
          <Input
            type={type || 'text'}
            ref={register}
            id={id}
            name={name}
            {...rest}
          />
          <Label>{label}</Label>
        </label>
      </Field>
      {error && <Error>{error.message}</Error>}
    </TextInputGroup>
  )
}

interface ControlledTextInputProps extends TextInputProps {
  control: any
}

export function ControlledTextInput({
  control,
  type,
  id,
  name,
  label,
  error,
  ...rest
}: ControlledTextInputProps) {
  const { field } = useController({
    name,
    control,
  })

  return (
    <TextInputGroup>
      <Field>
        <label>
          <Input
            type={type || 'text'}
            id={id}
            {...field}
            {...rest}
            $hasValue={!!field.value}
            $hasError={!!error}
          />
          <Label $hasValue={!!field.value} $hasError={!!error}>
            {label}
          </Label>
        </label>
      </Field>
      {error && <Error>{error.message}</Error>}
    </TextInputGroup>
  )
}

interface ControlledTextAreaProps extends TextInputProps {
  control: any
  placeholder: string
  rows?: number
}

export function ControlledTextArea({
  control,
  id,
  name,
  label,
  placeholder,
  error,
  ...rest
}: ControlledTextAreaProps) {
  const { field, meta } = useController({
    name,
    control,
  })

  return (
    <TextInputGroup>
      <Field>
        <label>
          <Input
            as="textarea"
            id={id}
            {...field}
            {...rest}
            $hasValue={!!field.value}
            $hasError={!!error}
            css={`
              resize: none;
            `}
          />
          <Label $hasValue={!!field.value} $hasError={!!error}>
            {meta.isDirty ? label : placeholder}
          </Label>
        </label>
      </Field>
      {error && <Error>{error.message}</Error>}
    </TextInputGroup>
  )
}
