import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Heading, Body } from 'src/components/Typography'

const Intro = styled.div`
  grid-column: 1 / -1;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 800px) {
    grid-column: 2 / 12;

    flex-direction: row;
  }
`

const StatColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  margin: 0 0 24px;

  &:last-child {
    margin: 0;
  }

  @media (min-width: 800px) {
    margin: 0 40px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`

const StatIcon = styled.img`
  height: 28px;
  width: auto;
  margin: 0 12px 0 0;

  @media (min-width: 800px) {
    height: clamp(34px, 2.65625vw, 51px);
  }
`

const StatFigure = styled.div<{ $hasIcon?: boolean }>`
  position: relative;

  &::before {
    background-color: var(--squash600);
    content: '';
    display: ${({ $hasIcon }) => ($hasIcon ? 'none' : 'block')};
    width: 4px;
    height: 82%;

    position: absolute;
    left: -16px;
    top: 10px;
  }
`

interface StatsSectionProps {
  data?: GatsbyTypes.StatsFieldsFragment
  top: boolean
  zIndex: number
}

export default function Stats({ data, top, zIndex }: StatsSectionProps) {
  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      <ColumnGrid>
        <Intro>
          <Heading
            $preset="h2"
            as="h2"
            $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
            $align={{ desktop: 'center', mobile: 'center' }}
            css={`
              margin: 0 0 16px;
            `}
          >
            {data?.headline}
          </Heading>
          <Heading
            $preset="h4"
            as="p"
            $colour={{
              desktop: 'var(--purple900)',
              mobile: 'var(--purple900)',
            }}
            $align={{ desktop: 'center', mobile: 'center' }}
            css={`
              margin: 0 0 48px;
            `}
          >
            {data?.subheadline}
          </Heading>
        </Intro>
        <Content>
          {data?.stats?.map((stat, index) => (
            <StatColumn key={index}>
              <div
                css={`
                  display: flex;
                  align-items: center;
                  margin: 0 0 14px;
                `}
              >
                {stat?.icon && (
                  <StatIcon
                    src={stat?.icon?.localFile?.publicURL}
                    alt={stat?.icon?.altText || 'Icon'}
                  />
                )}
                <StatFigure $hasIcon={!!stat?.icon}>
                  <Heading
                    $preset="h3"
                    as="h3"
                    $colour={{
                      desktop: 'var(--white)',
                      mobile: 'var(--white)',
                    }}
                    $align={{ desktop: 'left', mobile: 'left' }}
                    css={`
                      white-space: nowrap;
                    `}
                  >
                    {stat?.number}
                    {stat?.unit}
                  </Heading>
                </StatFigure>
              </div>

              <Body
                $preset="bodyOne"
                $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
              >
                {stat?.description}
              </Body>
            </StatColumn>
          ))}
        </Content>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment StatsSectionFields on WpContent_Acf_Layouts_Stats {
    acfeFlexibleLayoutTitle
    anchor
    background {
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    headline
    subheadline
    stats {
      icon {
        altText
        localFile {
          relativePath
          publicURL
        }
      }
      number
      unit
      description
    }
  }
`
