import React from 'react'
import { graphql } from 'gatsby'
import {
  Hero,
  Stats,
  VerticalCards,
  MasonryList,
  Form,
  Columns,
  Carousel,
  QuoteCarousel,
  ClientServices,
  Interactive,
  IxdCoreElements,
  IxdFieldsOfFocus,
} from 'src/components/Section'

import { LatestList, CategoryList } from 'src/components/Posts'

export const getLayouts = layouts =>
  Array.isArray(layouts)
    ? layouts.map((layout, index) => {
        const zIndex = layouts.length - index
        const top = index === 0

        switch (layout.__typename) {
          /* Reusable Layouts */
          case `WpContent_Acf_Layouts_Hero`:
            return <Hero key={index} zIndex={zIndex} top={top} data={layout} />
          case `WpContent_Acf_Layouts_Stats`:
            return <Stats key={index} zIndex={zIndex} top={top} data={layout} />
          case `WpContent_Acf_Layouts_VerticalCards`:
            return (
              <VerticalCards
                key={index}
                zIndex={zIndex}
                top={top}
                data={layout}
              />
            )
          case `WpContent_Acf_Layouts_MasonryList`:
            return (
              <MasonryList
                key={index}
                zIndex={zIndex}
                top={top}
                data={layout}
              />
            )
          case `WpContent_Acf_Layouts_Form`:
            return <Form key={index} zIndex={zIndex} top={top} data={layout} />
          case `WpContent_Acf_Layouts_Columns`:
            return (
              <Columns key={index} zIndex={zIndex} top={top} data={layout} />
            )
          case `WpContent_Acf_Layouts_Carousel`:
            return (
              <Carousel key={index} zIndex={zIndex} top={top} data={layout} />
            )
          case `WpContent_Acf_Layouts_QuoteCarousel`:
            return (
              <QuoteCarousel
                key={index}
                zIndex={zIndex}
                top={top}
                data={layout}
              />
            )
          case `WpContent_Acf_Layouts_ClientServices`:
            return (
              <ClientServices
                key={index}
                zIndex={zIndex}
                top={top}
                data={layout}
              />
            )
          /* Static Layouts */
          case `WpContent_Acf_Layouts_Interactive`:
            return <Interactive key={index} zIndex={zIndex} top={top} />
          case `WpContent_Acf_Layouts_IxdCoreElements`:
            return (
              <IxdCoreElements
                key={index}
                zIndex={zIndex}
                top={top}
                data={layout}
              />
            )
          case `WpContent_Acf_Layouts_IxdFieldsOfFocus`:
            return (
              <IxdFieldsOfFocus
                key={index}
                zIndex={zIndex}
                top={top}
                data={layout}
              />
            )
          case `WpContent_Acf_Layouts_LatestPosts`:
            return <LatestList key={index} zIndex={zIndex} top={top} />
          case `WpContent_Acf_Layouts_CategoryPosts`:
            return (
              <CategoryList
                key={index}
                zIndex={zIndex}
                top={top}
                data={layout}
              />
            )
          default:
            throw new Error(
              `[Error] Unhandled layout type ${layout.__typename}`
            )
        }
      })
    : null

export const layoutFragment = graphql`
  fragment LayoutFields on WpContent_Acf {
    layouts {
      __typename
      ...HeroFields
      ...StatsSectionFields
      ...VerticalCardsFields
      ...MasonryListFields
      ...FormFields
      ...ColumnsFields
      ...CarouselFields
      ...QuoteCarouselFields
      ...ClientServicesFields
      ...IXDCoreElementsFields
      ...IXDFieldsOfFocusFields
      ...LatestPostsFields
      ...CategoryPostsFields
    }
  }
`
