import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import {
  Slider,
  LinkStyledAsButton,
  UnstyledLink,
} from 'src/components/Elements'

import { fontBold, sizeH6, h3 } from 'src/lib/typography'

const Slide = styled.div`
  height: 139vw;
  overflow: hidden;
  position: relative;

  @media (min-width: 800px) {
    height: 50vw;
  }
`

const SlideContainer = styled.div`
  padding: 0 0 100px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 100%;
`

const BackgroundImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  grid-column: 1 / -1;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Category = styled.span`
  color: var(--squash300);
  display: block;
  ${fontBold}
  ${sizeH6}
  margin: 0 0 16px;
  text-align: center;
  text-transform: uppercase;
`

const Title = styled.h3`
  color: var(--white);
  ${h3}
  margin: 0 0 24px;
  text-align: center;
`

interface CarouselSectionProps {
  data?: any
  top: boolean
  zIndex: number
}

export default function Carousel({ data, top, zIndex }: CarouselSectionProps) {
  return (
    <Container
      anchor={data?.anchor}
      background={{
        divider: 'flat',
      }}
      top={top}
      zIndex={zIndex}
    >
      <Slider hasDots hasArrows>
        {data?.slides.map(slide => {
          const sources = withArtDirection(
            getImage(slide?.cover?.image?.mobile?.localFile),
            [
              {
                media: '(min-width: 800px)',
                image: getImage(slide?.cover?.image?.desktop?.localFile),
              },
            ]
          )

          return (
            <Slide key={slide.id}>
              <BackgroundImage
                image={sources}
                alt={
                  slide?.cover?.image?.desktop?.altText ||
                  slide?.cover?.image?.mobile?.altText ||
                  'Slide Image'
                }
              />
              <SlideContainer>
                <ColumnGrid>
                  <Content>
                    <Category>
                      {slide.categories?.nodes?.map((node, index) => {
                        if (index < slide?.categories?.nodes.length - 1) {
                          return (
                            <UnstyledLink key={node.id} to={node.uri}>
                              {`${node.name}, `}
                            </UnstyledLink>
                          )
                        } else
                          return (
                            <UnstyledLink key={node.id} to={node.uri}>
                              {node.name}
                            </UnstyledLink>
                          )
                      })}
                    </Category>
                    <Title>{slide.title}</Title>
                    <LinkStyledAsButton to={slide.uri}>
                      read article
                    </LinkStyledAsButton>
                  </Content>
                </ColumnGrid>
              </SlideContainer>
            </Slide>
          )
        })}
      </Slider>
    </Container>
  )
}

export const fragment = graphql`
  fragment CarouselDesktopImageFields on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        gatsbyImageData(
          backgroundColor: "#fefefe"
          layout: FULL_WIDTH
          placeholder: NONE
          quality: 90
          aspectRatio: 2
          transformOptions: {
            duotone: { highlight: "#4A0B64", shadow: "#4A0B64", opacity: 60 }
          }
        )
      }
    }
  }

  fragment CarouselMobileImageFields on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        gatsbyImageData(
          backgroundColor: "#fefefe"
          layout: FULL_WIDTH
          placeholder: NONE
          quality: 90
          aspectRatio: 0.72
          transformOptions: {
            duotone: { highlight: "#4A0B64", shadow: "#4A0B64", opacity: 60 }
          }
        )
      }
    }
  }

  fragment CarouselFields on WpContent_Acf_Layouts_Carousel {
    acfeFlexibleLayoutTitle
    anchor
    slides {
      __typename
      ... on WpPost {
        id
        title
        uri
        categories {
          nodes {
            id
            name
            uri
          }
        }
        cover {
          image {
            desktop {
              ...CarouselDesktopImageFields
            }
            mobile {
              ...CarouselMobileImageFields
            }
          }
        }
      }
    }
  }
`
