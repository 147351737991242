import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'src/components/Layout'
import { ContactForm } from 'src/components/Forms'

function getForm(form: string | undefined) {
  switch (form) {
    case 'contact':
      return <ContactForm />
    default:
      throw new Error('[Error] Unhandled form type')
  }
}

interface FormSectionProps {
  data?: GatsbyTypes.FormFieldsFragment
  top: boolean
  zIndex: number
}

export default function Form({ data, top, zIndex }: FormSectionProps) {
  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      {getForm(data?.form)}
    </Container>
  )
}

export const fragment = graphql`
  fragment FormFields on WpContent_Acf_Layouts_Form {
    acfeFlexibleLayoutTitle
    anchor
    background {
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    form
  }
`
