import * as yup from 'yup'

export const contactSchema = yup.object().shape({
  firstName: yup.string().required('First name is a required field.'),
  lastName: yup.string().required('Last name is a required field.'),
  company: yup.string(),
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Email address is a required field.'),
  phoneNumber: yup
    .string()
    .matches(
      /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/g,
      'Invalid Phone Number, allowed characters: 0-9, +, -, (),'
    ),
  reason: yup.string(),
  contactMethod: yup.string(),
  message: yup.string(),
})

export interface ContactInputs extends yup.TypeOf<typeof contactSchema> {}
