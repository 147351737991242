import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Heading, Body } from 'src/components/Typography'

const Intro = styled.div`
  grid-column: 1 / -1;
  margin: 0 0 25px;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
    margin: 0 0 36px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;

  @media (min-width: 800px) {
    grid-column: 2 / 11;

    /* display: grid;
    grid-template-columns: masonry; */

    column-count: 2;
    column-fill: auto;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  /* display: flex;
  flex-direction: column; */
  break-inside: avoid;
  margin: 0 0 50px;
`

const ListHeading = styled.span`
  display: flex;
  margin: 0 0 32px;
`

const Icon = styled.div<{ icon: string | null | undefined }>`
  background-color: var(--purple400);
  background-image: ${({ icon }) => `url(${icon})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
  border-radius: 0 0 12px 0;
  margin: 0 20px 0 0;
  height: 40px;
  width: 40px;

  @media (min-width: 800px) {
    background-size: 36px auto;
    border-radius: 0 0 24px 0;

    height: 60px;
    width: 60px;
  }
`

interface MasonryListProps {
  data?: GatsbyTypes.MasonryListFieldsFragment
  top: boolean
  zIndex: number
}

export default function MasonryList({ data, top, zIndex }: MasonryListProps) {
  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      <ColumnGrid>
        <Intro>
          <Heading
            $preset="h3"
            as="h3"
            $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
            $align={{ desktop: 'left', mobile: 'left' }}
            css={`
              margin: 0 0 32px;
            `}
          >
            {data?.headline}
          </Heading>
          <Heading
            $preset="h4"
            as="p"
            $colour={{
              desktop: 'var(--turquoise300)',
              mobile: 'var(--turquoise300)',
            }}
            $align={{ desktop: 'left', mobile: 'left' }}
          >
            {data?.subheadline}
          </Heading>
        </Intro>
        <Content>
          <List>
            {data?.list?.map((item, index) => (
              <ListItem key={index}>
                <ListHeading>
                  <Icon icon={item?.icon?.localFile?.publicURL} />
                  <Heading
                    $preset="h5"
                    as="h5"
                    $colour={{
                      desktop: 'var(--purple900)',
                      mobile: 'var(--purple900)',
                    }}
                  >
                    {item?.heading}
                  </Heading>
                </ListHeading>
                <ul
                  style={{ listStyleType: 'disc', color: 'var(--squash300)' }}
                >
                  {item?.listItems?.map((listItem, index) => (
                    <li key={index} style={{ margin: '0 0 16px' }}>
                      <Body
                        $preset="bodyOne"
                        as="span"
                        $colour={{
                          desktop: 'var(--black)',
                          mobile: 'var(--black)',
                        }}
                      >
                        {listItem?.description}
                      </Body>
                    </li>
                  ))}
                </ul>
              </ListItem>
            ))}
          </List>
        </Content>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment MasonryListFields on WpContent_Acf_Layouts_MasonryList {
    acfeFlexibleLayoutTitle
    anchor
    background {
      kind
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    headline
    subheadline
    list {
      icon {
        localFile {
          relativePath
          publicURL
        }
      }
      heading
      listItems {
        description
      }
    }
  }
`
