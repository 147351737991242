import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Heading, Body } from 'src/components/Typography'
import { LinkStyledAsButton } from 'src/components/Elements'

const FeaturedHeroSection = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  min-height: 675px;
  overflow-x: hidden;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: clamp(600px, 46.875vw, 900px);
  }
`

const HeroSection = styled(Container)`
  display: flex;
  flex-direction: row;
`

const CaptionContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;

  align-items: ${({ $align }) =>
    $align === 'start'
      ? 'flex-start'
      : $align === 'end'
      ? 'flex-end'
      : 'center'};

  @media (min-width: 800px) {
    grid-column: 2 / 12;
  }
`

const Caption = styled.div`
  grid-column: 1 / -1;
  margin: 40px 0;

  @media (min-width: 800px) {
    grid-column: 2 / 8;
    margin: 0;
  }
`

function Content({
  data,
}: {
  data: Pick<
    GatsbyTypes.HeroFieldsFragment,
    'align' | 'headline' | 'subheadline' | 'hasButton' | 'button'
  >
}) {
  const textAlign =
    data?.align === 'center'
      ? 'center'
      : data?.align === 'end'
      ? 'right'
      : 'left'

  return (
    <CaptionContainer $align={data?.align}>
      {data?.headline && (
        <Heading
          $preset="h1"
          as="h2"
          $colour={{ desktop: 'var(--white)', mobile: 'var(--white)' }}
          $align={{ desktop: textAlign, mobile: textAlign }}
          css={`
            ${data?.subheadline && 'margin: 0 0 32px;'}
          `}
          dangerouslySetInnerHTML={{ __html: data?.headline }}
        />
      )}
      {data?.subheadline && (
        <Body
          $preset="bodyLarge"
          as="div"
          $colour={{ desktop: 'var(--white)', mobile: 'var(--white)' }}
          $align={{ desktop: textAlign, mobile: textAlign }}
          css={`
            ${data?.hasButton && 'margin: 0 0 40px;'}
          `}
          dangerouslySetInnerHTML={{ __html: data?.subheadline }}
        />
      )}
      {data?.hasButton && (
        <LinkStyledAsButton
          to={
            data?.button?.destination?.wpInternal?.post?.uri ||
            data?.button?.destination?.external?.url ||
            ''
          }
        >
          {data?.button?.cta}
        </LinkStyledAsButton>
      )}
    </CaptionContainer>
  )
}

interface HeroProps {
  data: GatsbyTypes.HeroFieldsFragment
  zIndex: number
  top: boolean
}

export default function Hero({ data, top, zIndex }: HeroProps) {
  if (data?.preset === 'featured') {
    return (
      <FeaturedHeroSection
        anchor={data?.anchor}
        background={data?.background}
        padding={data?.padding}
        zIndex={zIndex}
        top={top}
        featured
      >
        <ColumnGrid
          css={`
            z-index: 2;

            @media (min-width: 800px) {
              flex: 1;
              place-items: start;
              height: 100%;
              width: 100%;
            }
          `}
        >
          <Caption>
            <Content
              data={{
                align: 'start',
                headline: data?.headline,
                subheadline: data?.subheadline,
                hasButton: data?.hasButton,
                button: data?.button,
              }}
            />
          </Caption>
        </ColumnGrid>
      </FeaturedHeroSection>
    )
  }

  return (
    <HeroSection
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      zIndex={zIndex}
      top={top}
    >
      <ColumnGrid
        css={`
          height: 100%;
          place-content: center ${data?.align};
        `}
      >
        <Content
          data={{
            align: data?.align,
            headline: data?.headline,
            subheadline: data?.subheadline,
            hasButton: data?.hasButton,
            button: data?.button,
          }}
        />
      </ColumnGrid>
    </HeroSection>
  )
}

export const fragment = graphql`
  fragment HeroFields on WpContent_Acf_Layouts_Hero {
    acfeFlexibleLayoutTitle
    anchor
    background {
      kind
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    preset
    align
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    headline
    subheadline
    hasButton
    button {
      cta
      destination {
        type
        wpInternal {
          post {
            ... on WpPage {
              uri
            }
            ... on WpPost {
              uri
            }
          }
          anchor
        }
        external {
          url
        }
      }
    }
  }
`
