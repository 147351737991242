import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Heading, Body } from 'src/components/Typography'
import { backgroundColour, Colour } from 'src/lib/styles'

import { motion, AnimatePresence } from 'framer-motion'

const Intro = styled.div`
  grid-column: 1 / -1;
  margin: 0 0 32px;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
    margin: 0 0 48px;
  }
`
const Content = styled.div`
  grid-column: 1 / -1;

  display: grid;
  grid-template-areas:
    'one'
    'two'
    'three'
    'four'
    'five';
  grid-gap: 16px;

  @media (min-width: 800px) {
    grid-column: 2 / 12;

    grid-template-areas:
      'one one two two three three'
      '. four four five five .';
    grid-gap: 40px;
  }
`

const Shape = styled(motion.div)`
  ${backgroundColour}
  border-radius: 24px 0 24px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;

  @media (min-width: 800px) {
    border-radius: 24px 0 48px 0;
    padding: 32px 0;
  }
`

const Bubble = styled(motion.div)`
  background-color: var(--white);
  border: none;
  border-radius: 24px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &::before {
    content: '';
    display: block;
    border-top: 16px solid var(--white);
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    width: 0;
    height: 0;
    transition: opacity 0.25s;
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.5s;
  }
`

const bubbleVariants = {
  hidden: {
    y: 0,
    transition: { duration: 0.1, delay: 0.1 },
  },
  visible: {
    y: 'calc(-100% - 20px)',
    transition: {
      type: 'spring',
      duration: 0.25,
      staggerChildren: 0.1,
    },
  },
}

const textVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

interface IxdButtonProps {
  $bgColour: Colour
  position: string
  title: string
  content: string
}

const positions = [
  {
    name: 'one',
    bgColour: {
      desktop: 'var(--purple400)',
      mobile: 'var(--purple400)',
    },
  },
  {
    name: 'two',
    bgColour: {
      desktop: 'var(--squash300)',
      mobile: 'var(--squash300)',
    },
  },
  {
    name: 'three',
    bgColour: {
      desktop: 'var(--turquoise300)',
      mobile: 'var(--turquoise300)',
    },
  },
  {
    name: 'four',
    bgColour: {
      desktop: 'var(--turquoise700)',
      mobile: 'var(--turquoise700)',
    },
  },
  {
    name: 'five',
    bgColour: {
      desktop: 'var(--purple900)',
      mobile: 'var(--purple900)',
    },
  },
]

function IxdButton({ $bgColour, position, title, content }: IxdButtonProps) {
  const [hover, setHover] = useState(false)
  const { ref, inView } = useInView()

  const outsideClickListener = (event: TouchEvent) => {
    if (event.target && event.target.closest(`#${position}`) === null) {
      setHover(false)
    }
  }

  useEffect(() => {
    if (hover) {
      document && document.addEventListener('touchstart', outsideClickListener)
    } else {
      document &&
        document.removeEventListener('touchstart', outsideClickListener)
    }

    return () =>
      document &&
      document.removeEventListener('touchstart', outsideClickListener)
  }, [hover])

  return (
    <motion.div
      css={`
        position: relative;
        grid-area: ${position};
      `}
      onTap={() => setHover(prevState => !prevState)}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <AnimatePresence>
        {hover && (
          <Bubble
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={bubbleVariants}
          >
            <Body
              $preset="bodyOne"
              as={motion.p}
              $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
              $align={{ desktop: 'center', mobile: 'center' }}
              css={`
                padding: 14px;
              `}
              variants={textVariants}
            >
              {content}
            </Body>
          </Bubble>
        )}
      </AnimatePresence>
      <Shape
        ref={ref}
        id={position}
        $bgColour={$bgColour}
        animate={
          inView &&
          position === 'one' && {
            scale: [0.98, 1.02, 0.98],
            transition: { duration: 2, repeat: Infinity },
          }
        }
      >
        <Heading
          $preset="h4"
          as="h3"
          $colour={{ desktop: 'var(--white)', mobile: 'var(--white)' }}
        >
          {title}
        </Heading>
      </Shape>
    </motion.div>
  )
}

interface IxdCoreElementsProps {
  data?: GatsbyTypes.IXDCoreElementsFieldsFragment
  top: boolean
  zIndex: number
}

export default function IxdCoreElements({
  data,
  top,
  zIndex,
}: IxdCoreElementsProps) {
  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      <ColumnGrid>
        <Intro>
          <Heading $preset="h3" as="h2">
            <span style={{ color: 'var(--purple400)' }}>I</span>
            <span style={{ color: 'var(--squash300)' }}>X</span>
            <span style={{ color: 'var(--turquoise300)' }}>D</span>
            <span
              css={`
                color: var(--turquoise300);
                font-size: 18px;
                line-height: 25px;
                vertical-align: top;

                @media (min-width: 800px) {
                  line-height: 34px;
                }
              `}
            >
              ™
            </span>{' '}
            core elements
          </Heading>
        </Intro>
        <Content>
          {Array.isArray(data?.elements) &&
            data?.elements?.map((element, index) => (
              <IxdButton
                key={index}
                $bgColour={positions[index].bgColour}
                position={positions[index].name}
                title={element?.title}
                content={element?.content}
              />
            ))}
        </Content>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment IXDCoreElementsFields on WpContent_Acf_Layouts_IxdCoreElements {
    acfeFlexibleLayoutTitle
    anchor
    background {
      kind
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    elements {
      title
      content
    }
  }
`
