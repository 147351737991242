import React from 'react'
import { graphql } from 'gatsby'
import {
  PreformattedText,
  List,
  CmsButton,
  CmsImage,
  Gallery,
  Stats,
  Video,
} from 'src/components/Elements'

export const getElements = elements =>
  Array.isArray(elements)
    ? elements.map((element, index) => {
        switch (element.__typename) {
          case `WpContent_Acf_Layouts_Columns_columns_Elements_PreformattedText`:
            return <PreformattedText key={index} data={element} />
          case `WpContent_Acf_Layouts_Columns_columns_Elements_Stats`:
            return <Stats key={index} data={element} />
          case `WpContent_Acf_Layouts_Columns_columns_Elements_List`:
            return <List key={index} data={element} />
          case `WpContent_Acf_Layouts_Columns_columns_Elements_Button`:
            return <CmsButton key={index} data={element} />
          case `WpContent_Acf_Layouts_Columns_columns_Elements_Image`:
            return <CmsImage key={index} data={element} />
          case `WpContent_Acf_Layouts_Columns_columns_Elements_Gallery`:
            return <Gallery key={index} data={element} />
          case `WpContent_Acf_Layouts_Columns_columns_Elements_Video`:
            return <Video key={index} data={element} />
          default:
            throw new Error(
              `[Error] Unhandled element type ${element.__typename}`
            )
        }
      })
    : null

export const elementFragment = graphql`
  fragment ElementFields on WpContent_Acf_Layouts_Columns_columns_Elements {
    __typename
    ...PreformattedTextFields
    ...StatsFields
    ...ListFields
    ...ButtonFields
    ...ImageFields
    ...GalleryFields
    ...VideoFields
  }
`
