import React from 'react'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Heading, Body } from 'src/components/Typography'
import { Image } from 'src/components/Elements'

const Background = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  margin: -100px -32px 0;
  position: relative;

  @media (min-width: 800px) {
    grid-column: 7 / 13;
    margin: 0 clamp(-60px, -3.125vw, -40px) auto 0;

    .gatsby-image-wrapper {
      border-radius: 160px 0 0 0;
    }
  }
`

const BirdLogoSvg = styled(require('src/images/logos/colloqui-bird.svg'))`
  position: absolute;
  bottom: -10px;
  right: 80px;

  height: 150px;
  width: auto;

  path {
    fill: var(--beige);
  }
`

const Intro = styled.div`
  grid-column: 1 / -1;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 80px 0 0;
  z-index: 2;

  @media (min-width: 800px) {
    grid-column: 1 / 8;
    flex-direction: row;
  }
`

const CardsList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`

const CardListItem = styled.li`
  flex: 0;
  margin: 0 0 16px;

  &:last-child {
    margin: 0;
  }

  @media (min-width: 800px) {
    margin: 0 0 clamp(40px, 3.125vw, 60px);
  }
`

type CardBodyProps = {
  cardTheme: 'turquoise' | 'squash' | 'purple' | string | null | undefined
}

const CardBody = styled(motion.article)<CardBodyProps>`
  background-color: ${({ cardTheme }) => {
    switch (cardTheme) {
      case 'turquoise':
        return `var(--turquoise300)`
      case 'squash':
        return `var(--squash300)`
      case 'purple':
        return `var(--purple900)`
      default:
        throw new Error('[Error] Unhandled theme type')
    }
  }};
  border-radius: 24px 0 48px 0;
  padding: 32px;

  @media (min-width: 800px) {
    border-radius: 48px 0 96px 0;
    padding: 60px 100px 60px 100px;
  }
`

const bodyVariants = {
  hidden: {
    opacity: 0,
    x: '-100%',
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
      delayChildren: 0.5,
    },
  },
}

const textVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

function Card({ card }) {
  const { ref, inView } = useInView()

  return (
    <CardBody
      cardTheme={card?.theme}
      ref={ref}
      initial="hidden"
      animate={inView && 'visible'}
      variants={bodyVariants}
    >
      <Heading
        $preset="h5"
        as={motion.span}
        $colour={{
          desktop:
            card?.theme === 'purple' ? 'var(--squash300)' : 'var(--purple900)',
          mobile:
            card?.theme === 'purple' ? 'var(--squash300)' : 'var(--purple900)',
        }}
        $align={{
          desktop: 'left',
          mobile: 'left',
        }}
        css={`
          margin: 0 0 4px;
        `}
        variants={textVariants}
      >
        {card?.eyebrow}
      </Heading>
      <Heading
        $preset="h3"
        as={motion.h3}
        $colour={{
          desktop: 'var(--white)',
          mobile: 'var(--white)',
        }}
        $align={{
          desktop: 'left',
          mobile: 'left',
        }}
        css={`
          margin: 0 0 16px;
        `}
        variants={textVariants}
      >
        {card?.title}
      </Heading>
      <Body
        $preset="bodyOne"
        as={motion.p}
        $colour={{
          desktop: card?.theme === 'purple' ? 'var(--white)' : 'var(--black)',
          mobile: card?.theme === 'purple' ? 'var(--white)' : 'var(--black)',
        }}
        variants={textVariants}
      >
        {card?.description}
      </Body>
    </CardBody>
  )
}

interface VerticalCardsProps {
  data?: GatsbyTypes.VerticalCardsFieldsFragment
  top: boolean
  zIndex: number
}

export default function VerticalCards({
  data,
  top,
  zIndex,
}: VerticalCardsProps) {
  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      <ColumnGrid>
        <Background>
          <Image source={data?.featured?.image} />
          <BirdLogoSvg />
        </Background>
        <Intro>
          {data?.headline && (
            <Heading
              $preset="h2"
              as="h2"
              $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
              $align={{ desktop: 'center', mobile: 'center' }}
              css={`
                margin: 0 0 16px;
              `}
            >
              {data?.headline}
            </Heading>
          )}
          {data?.subheadline && (
            <Heading
              $preset="h4"
              as="p"
              $colour={{
                desktop: 'var(--purple900)',
                mobile: 'var(--purple900)',
              }}
              $align={{ desktop: 'center', mobile: 'center' }}
              css={`
                margin: 0 0 48px;
              `}
            >
              {data?.subheadline}
            </Heading>
          )}
        </Intro>
        <Content>
          <CardsList>
            {data?.cards?.map((card, index) => (
              <CardListItem key={index}>
                <Card card={card} />
              </CardListItem>
            ))}
          </CardsList>
        </Content>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment VerticalCardsFields on WpContent_Acf_Layouts_VerticalCards {
    acfeFlexibleLayoutTitle
    anchor
    background {
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    headline
    subheadline
    cards {
      theme
      eyebrow
      title
      description
    }
    featured {
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
    }
  }
`
