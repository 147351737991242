import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Slider } from 'src/components/Elements'
import { Navigation, Dot } from 'src/components/Elements/Slider'
import { fontBold, preset, sizeH6 } from 'src/lib/typography'
import { colour } from 'src/lib/styles'

const Carousel = styled.div`
  border: 1px solid ${({ $colour }) => $colour?.primary || 'var(--squash300)'};
  border-radius: 24px 0 48px 0;
  padding-top: clamp(20px, 3.125vw, 60px);
  padding-right: clamp(25px, 3.90625vw, 75px);
  padding-bottom: clamp(30px, 4.6875vw, 90px);
  padding-left: clamp(58px, 9.0625vw, 174px);
  position: relative;

  grid-column: 1 / 7;

  ${Navigation} {
    bottom: -32px;

    @media (min-width: 800px) {
      bottom: -48px;
    }
  }

  ${Dot} {
    --selected: transparent;
  }

  @media (min-width: 800px) {
    grid-column: 2 / 12;
    border-radius: 48px 0 96px 0;
  }
`

const Slide = styled.div`
  padding-left: 58px;
  padding-right: 25px;
  margin-left: -58px;

  @media (min-width: 800px) {
    padding-left: 116px;
    padding-right: 50px;
    margin-left: -116px;
  }
`

const QuoteMark = styled(require('src/images/icons/quote-mark.svg'))`
  position: absolute;
  left: 25px;
  top: 20px;

  @media (min-width: 800px) {
    left: 50px;
    top: 40px;
  }
`

const Quote = styled.blockquote`
  ${preset}
  ${colour}
  margin: 0 0 16px;
`

const Byline = styled.span`
  ${fontBold}
  ${sizeH6}
${colour}
`

export default function QuoteCarousel({ data, top, zIndex }) {
  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      <ColumnGrid>
        <Carousel $colour={data?.colourOptions}>
          <QuoteMark />
          <Slider hasDots>
            {data?.items?.map((item, index) => (
              <Slide key={index}>
                <Quote
                  $preset="h6"
                  $colour={{
                    desktop: data?.colourOptions.quote,
                    mobile: data?.colourOptions.quote,
                  }}
                >
                  {item.quote}
                </Quote>
                <Byline
                  $colour={{
                    desktop: data?.colourOptions.byline,
                    mobile: data?.colourOptions.byline,
                  }}
                >
                  {item.byline}
                </Byline>
              </Slide>
            ))}
          </Slider>
        </Carousel>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment QuoteCarouselFields on WpContent_Acf_Layouts_QuoteCarousel {
    acfeFlexibleLayoutTitle
    anchor
    background {
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    colourOptions {
      primary
      quoteMark
      quote
      byline
    }
    items {
      quote
      byline
    }
  }
`
