import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { contactSchema } from 'src/components/Forms'
import { ContactInputs } from 'src/components/Forms/schema'
import { ColumnGrid } from 'src/components/Layout'
import { Heading } from 'src/components/Typography'
import { ButtonStyledAsButton, CEO } from 'src/components/Elements'
import {
  ControlledTextInput,
  RadioInput,
  ControlledTextArea,
} from 'src/components/Inputs'
import { trackForm } from 'src/lib/analytics/segment'

const Form = styled.form`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  padding: 40px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 800px) {
    grid-column: 2 / 8;

    padding: 80px 0;
  }
`

const TextInputRow = styled.div`
  flex-basis: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 2px;

  @media (min-width: 800px) {
    flex-direction: row;
    margin: 0 0 2px;
  }
`

const RadioInputRow = styled.fieldset`
  border: none;
  flex-basis: 100%;
  margin: 0 0 48px;
  padding: 0;
  width: 100%;
`

type EncodedData = {
  [key: string]: string | undefined
}

const encode = (data: EncodedData) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function ContactForm() {
  const [success, setSuccess] = useState(false)
  const { register, handleSubmit, control, errors, reset } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(contactSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      phoneNumber: '',
      reason: 'Learn more about Colloqui',
      contactMethod: 'Email',
      message: '',
    },
  })

  const onSubmit = (data: ContactInputs) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...data }),
    })
      .then(() => {
        setSuccess(true)
        trackForm()
      })
      .catch(err => console.log(err))
      .finally(() => reset())
  }

  return (
    <ColumnGrid
      css={`
        place-items: start center;
      `}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        name="contact"
        data-netlify={true}
      >
        <TextInputRow>
          <ControlledTextInput
            name="firstName"
            label="First Name*"
            control={control}
            error={errors.firstName}
          />

          <ControlledTextInput
            name="lastName"
            label="Last Name*"
            control={control}
            error={errors.lastName}
          />
        </TextInputRow>
        <TextInputRow>
          <ControlledTextInput
            name="company"
            label="Company"
            control={control}
            error={errors.company}
          />
        </TextInputRow>
        <TextInputRow>
          <ControlledTextInput
            name="email"
            label="Email Address*"
            control={control}
            error={errors.email}
          />
        </TextInputRow>
        <TextInputRow
          css={`
            margin: 0 0 48px;
          `}
        >
          <ControlledTextInput
            name="phoneNumber"
            label="Phone Number"
            control={control}
            error={errors.phoneNumber}
          />
        </TextInputRow>
        <RadioInputRow>
          <Heading
            $preset="h6"
            as="legend"
            $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
            css={`
              margin: 0 0 20px;
            `}
          >
            Please select the main reason for connecting with us:
          </Heading>
          <RadioInput
            name="reason"
            label="Learn more about Colloqui"
            value="Learn more about Colloqui"
            register={register}
            defaultChecked
          />

          <RadioInput
            name="reason"
            label="Send us an RFP"
            value="Send us an RFP"
            register={register}
          />

          <RadioInput
            name="reason"
            label="Discuss your great project"
            value="Discuss your great project"
            register={register}
          />

          <RadioInput
            name="reason"
            label="Other"
            value="Other"
            register={register}
          />
        </RadioInputRow>
        <RadioInputRow>
          <Heading
            $preset="h6"
            as="legend"
            $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
            css={`
              margin: 0 0 20px;
            `}
          >
            How would you like to be contacted?
          </Heading>
          <RadioInput
            name="contactMethod"
            label="Email"
            value="Email"
            register={register}
            defaultChecked
          />

          <RadioInput
            name="contactMethod"
            label="Phone"
            value="Phone"
            register={register}
          />

          <RadioInput
            name="contactMethod"
            label="Colloqui messenger bird"
            value="Colloqui messenger bird"
            register={register}
          />
        </RadioInputRow>
        <TextInputRow>
          <ControlledTextArea
            name="message"
            label="Message"
            placeholder="Please share any additional details with us"
            rows={4}
            control={control}
            error={errors.message}
          />
        </TextInputRow>
        <ButtonStyledAsButton
          type="submit"
          css={`
            margin: 24px 0 0;
            min-width: 183px;
          `}
        >
          Send
        </ButtonStyledAsButton>
        {success && (
          <Heading
            $preset="h6"
            as="span"
            $colour={{
              mobile: 'var(--black)',
              desktop: 'var(--black)',
            }}
            $align={{
              mobile: 'center',
              desktop: 'center',
            }}
            css={`
              display: block;
            `}
          >
            Thank you for your message.
          </Heading>
        )}
      </Form>
      <CEO
        css={`
          display: none;

          @media (min-width: 800px) {
            display: flex;
            grid-column: 9 / 13;
            grid-row: 1 / 2;
          }
        `}
      />
    </ColumnGrid>
  )
}
