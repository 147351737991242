import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Body } from 'src/components/Typography'
import { fontRoman, fontBold } from 'src/lib/typography'
import { vwRatio, maxRatio } from 'src/lib/styles'

const Content = styled.div`
  grid-column: 1 / -1;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
  }
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
  }
`

const NavItem = styled.li`
  grid-row: ${({ $index }) => 2 * $index + 1};

  background-color: ${({ $active }) =>
    $active ? 'var(--purple900)' : 'var(--purple50)'};
  border-radius: 24px 0 0 0;
  cursor: pointer;
  margin: 4px 0 0;
  padding: 16px 22px;

  transition: background-color 0.25s ease-in;

  @media (min-width: 800px) {
    grid-row: ${({ $index }) => $index + 1};

    background-color: ${({ $active }) =>
      $active ? 'var(--purple900)' : 'var(--white)'};
    border-radius: 0;
    margin: ${({ $active }) => ($active ? '0 0 0 -20px' : '0')};

    padding: 36px 40px;

    &:hover,
    &:focus {
      margin: 0 0 0 -20px;
    }
  }
`

const NavHeading = styled.span`
  ${({ $active }) => ($active ? fontBold : fontRoman)}
  font-size: clamp(16px, ${16 * vwRatio}vw, ${16 * maxRatio}px);
  line-height: clamp(21px, ${21 * vwRatio}vw, ${21 * maxRatio}px);
  color: ${({ $active }) => ($active ? 'var(--white)' : 'var(--purple900)')};
  font-weight: ${({ $active }) => ($active ? 'bold' : 'normal')};

  position: relative;

  transition: color 0.25s ease-in;

  ${NavItem}:hover &,
  ${NavItem}:focus & {
    ${fontBold}
  }

  &::after {
    /* down arrow */
    border-top: 5px solid var(--turquoise300);
    border-right: 5px solid transparent;
    border-bottom: none;
    border-left: 5px solid transparent;
    content: '';
    display: block;
    width: 0;
    height: 0;

    position: absolute;
    right: -1em;
    bottom: 5px;

    transform: ${({ $active }) => $active && 'rotate(180deg)'};
    transition: transform 0.5s;

    @media (min-width: 800px) {
      /* right arrow */
      border-top: 5px solid transparent;
      border-right: none;
      border-bottom: 5px solid transparent;
      border-left: 5px solid var(--turquoise300);
      /* display: ${({ $active }) => ($active ? 'none' : 'block')}; */
      opacity: ${({ $active }) => ($active ? '0' : '1')};
      transform: unset;

      transition: opacity 0.5s ease-in;

      bottom: 2px;
    }
  }
`

const NavContent = styled.div`
  grid-row: ${({ $index }) => 2 * $index + 2};

  background-color: var(--white);
  border-radius: 0 0 24px 0;
  padding: 16px 16px;

  @media (min-width: 800px) {
    grid-row: ${({ $length }) => ` 1 / ${$length + 1}`};

    background-color: var(--purple50);
    border-radius: 0 0 96px 0;
    padding: 36px 30px;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 32px;
  list-style-type: disc;
  color: var(--turquoise300);
`

const ListItem = styled.li`
  margin: 0 0 16px;
`

const getContent = ({ tab, services }) => (
  <List>
    {services[tab].items.map(item => (
      <ListItem key={item.content}>
        <Body $preset="bodyOne" as="span">
          {item.content}
        </Body>
      </ListItem>
    ))}
  </List>
)

interface ClientServicesProps {
  data?: GatsbyTypes.ClientServicesFieldsFragment
  zIndex: number
  top: boolean
}

export default function ClientServices({
  data,
  top,
  zIndex,
}: ClientServicesProps) {
  const [tab, setTab] = useState(0)

  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      <ColumnGrid>
        <Content>
          <NavList>
            {data?.services.map((service, index) => (
              <NavItem
                key={service?.title || index}
                onClick={() => setTab(index)}
                $active={tab === index}
                $index={index}
              >
                <NavHeading $active={tab === index}>
                  {service?.title}
                </NavHeading>
              </NavItem>
            ))}
            <NavContent $index={tab} $length={data?.services?.length}>
              {getContent({ tab, services: data?.services })}
            </NavContent>
          </NavList>
        </Content>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment ClientServicesFields on WpContent_Acf_Layouts_ClientServices {
    acfeFlexibleLayoutTitle
    anchor
    background {
      kind
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    services {
      title
      items {
        content
      }
    }
  }
`
