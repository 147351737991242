import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Heading, Body } from 'src/components/Typography'

const Intro = styled.div`
  grid-column: 1 / -1;
  margin: 0 0 32px;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
    margin: 0 0 48px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;

  display: grid;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 50px;
  }
`

const CircleContainer = styled.div`
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    padding-top: 100%;
  }
`

const Circle = styled.div`
  background-color: var(--white);
  background-image: ${({ icon }) => `url(${icon})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px auto;
  border-radius: 50%;
  box-sizing: border-box;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &::before,
  &::after {
    background-color: transparent;
    box-sizing: border-box;

    content: '';
    position: absolute;

    height: 50%;
    width: 100%;
  }

  ${CircleContainer}:nth-child(2n+1) &::before {
    z-index: 100;
  }
  ${CircleContainer}:nth-child(2n+2) &::before {
    z-index: 99;
  }
  ${CircleContainer}:nth-child(2n+3) &::before {
    z-index: 98;
  }

  &::before {
    top: 0;
    left: 0;

    display: ${({ borderTop }) => (borderTop ? 'block' : 'none')};

    border-bottom-left-radius: 0;
    border-right: ${({ borderTop }) =>
      borderTop ? `4px solid ${borderTop}` : 'none'};
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    border: ${({ borderTop }) =>
      borderTop ? `4px solid ${borderTop}` : 'none'};
    border-bottom: 0;
  }

  &::after {
    bottom: 0;
    right: 0;

    display: ${({ borderBottom }) => (borderBottom ? `block` : `none`)};
    border: ${({ borderBottom }) =>
      borderBottom ? `4px solid ${borderBottom}` : 'none'};

    border-top-right-radius: 0;
    border-left: ${({ borderBottom }) =>
      borderBottom ? `4px solid ${borderBottom}` : 'none'};
    border-bottom-left-radius: 100% 200%;
    border-bottom-right-radius: 100% 200%;
    border-top: 0;
  }

  @media (min-width: 800px) {
    background-size: 70px auto;
  }
`

const Infographic = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  margin: 48px 0;

  & > * {
    margin: auto -4px auto 0;
  }
`

interface IxdFieldsOfFocusProps {
  data?: GatsbyTypes.IXDFieldsOfFocusFieldsFragment
  top: boolean
  zIndex: number
}

export default function IxdFieldsOfFocus({
  data,
  top,
  zIndex,
}: IxdFieldsOfFocusProps) {
  return (
    <Container
      anchor={data?.anchor}
      background={data?.background}
      padding={data?.padding}
      top={top}
      zIndex={zIndex}
    >
      <ColumnGrid>
        <Intro>
          <Heading $preset="h3" as="h2">
            <span style={{ color: 'var(--purple400)' }}>I</span>
            <span style={{ color: 'var(--squash300)' }}>X</span>
            <span style={{ color: 'var(--turquoise300)' }}>D</span>
            <span
              css={`
                color: var(--turquoise300);
                font-size: 18px;
                line-height: 25px;
                vertical-align: top;

                @media (min-width: 800px) {
                  line-height: 34px;
                }
              `}
            >
              ™
            </span>{' '}
            fields of focus
          </Heading>
        </Intro>
        <Content>
          <div>
            <Heading
              $preset="h5"
              $colour={{
                desktop: 'var(--purple900)',
                mobile: 'var(--purple900)',
              }}
            >
              Audiences want
            </Heading>
            <ul style={{ padding: '0 0 0 32px' }}>
              <li style={{ color: 'var(--squash300)', margin: '0 0 16px' }}>
                <Body $preset="bodyOne">Relevant & engaging content</Body>
              </li>
              <li style={{ color: 'var(--squash300)', margin: '0 0 16px' }}>
                <Body $preset="bodyOne">Meaningful experience</Body>
              </li>
              <li style={{ color: 'var(--squash300)', margin: '0 0 16px' }}>
                <Body $preset="bodyOne">Device optimization</Body>
              </li>
            </ul>
          </div>
          <Infographic>
            <CircleContainer>
              <Circle
                icon="/mockups/audiences.svg"
                borderTop="var(--turquoise300)"
              />
            </CircleContainer>
            <CircleContainer>
              <Circle
                icon="/mockups/ixd.svg"
                borderTop="var(--squash300)"
                borderBottom="var(--turquoise300)"
              />
            </CircleContainer>
            <CircleContainer>
              <Circle
                icon="/mockups/organizations.svg"
                borderBottom="var(--squash300)"
              />
            </CircleContainer>
          </Infographic>
          <div>
            <Heading
              $preset="h5"
              $colour={{
                desktop: 'var(--purple900)',
                mobile: 'var(--purple900)',
              }}
            >
              Organizations provide
            </Heading>
            <ul style={{ padding: '0 0 0 32px' }}>
              <li style={{ color: 'var(--squash300)', margin: '0 0 16px' }}>
                <Body $preset="bodyOne">Strategic asset management</Body>
              </li>
              <li style={{ color: 'var(--squash300)', margin: '0 0 16px' }}>
                <Body $preset="bodyOne">Scalable and fast deployment</Body>
              </li>
              <li style={{ color: 'var(--squash300)', margin: '0 0 16px' }}>
                <Body $preset="bodyOne">Business impact</Body>
              </li>
            </ul>
          </div>
        </Content>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment IXDFieldsOfFocusFields on WpContent_Acf_Layouts_IxdFieldsOfFocus {
    acfeFlexibleLayoutTitle
    anchor
    background {
      kind
      colour {
        desktop
        mobile
      }
      hasFilter
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
      divider
    }
    padding {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
  }
`
