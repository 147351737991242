import React from 'react'
import styled from 'styled-components'
import { fontRoman } from 'src/lib/typography'
import { useController } from 'react-hook-form'

const RadioInputGroup = styled.div`
  margin: 0 0 16px;

  &:last-child {
    margin: 0;
  }
`

const Input = styled.input`
  height: 24px;
  width: 24px;
  margin: 0;
  padding: 0;
  opacity: 0;

  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
`

const Label = styled.label`
  cursor: pointer;
  ${fontRoman}
  font-size: 18px;
  line-height: 28px;
  padding: 0 0 0 36px;
  position: relative;
`

const Checkmark = styled.div`
  background-color: var(--squash50);
  border-radius: 50%;
  box-sizing: border-box;

  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;

  ${Input}:checked + & {
    border: 6px solid var(--purple900);
  }
`

interface RadioInputProps {
  id?: string
  name: string
  label: string
  value: string
  defaultChecked?: boolean
}

interface UncontrolledRadioInputProps extends RadioInputProps {
  register: any
}

export function RadioInput({
  register,
  id,
  name,
  label,
  value,
  ...rest
}: UncontrolledRadioInputProps) {
  return (
    <RadioInputGroup>
      <Label>
        <Input
          type="radio"
          ref={register}
          id={id}
          name={name}
          defaultValue={value}
          {...rest}
        />
        <Checkmark />
        {label}
      </Label>
    </RadioInputGroup>
  )
}

interface ControlledRadioInputProps extends RadioInputProps {
  control: any
}

export function ControlledRadioInput({
  control,
  id,
  name,
  label,
  ...rest
}: ControlledRadioInputProps) {
  const { field } = useController({
    name,
    control,
  })

  return (
    <RadioInputGroup>
      <Label>
        <Input type="radio" id={id} {...field} {...rest} />
        <Checkmark />
        {label}
      </Label>
    </RadioInputGroup>
  )
}
